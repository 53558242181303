import {useState, useEffect} from 'react'

const REFRESH_TIMEOUT = 150

const useWindowSize = () => {
  if (typeof window !== 'undefined') {
    const getSize = () => ({
      width: window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth,
      height: window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight
    })

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
      let timeoutId = null

      const handleResize = () => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => setWindowSize(getSize), REFRESH_TIMEOUT)
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize
  }

  return null
}

export default useWindowSize
