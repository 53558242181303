import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Parallax as ParallaxElem, useController} from 'react-scroll-parallax'

import useWindowSize from '../../util/window-size/useWindowSize'

const DEFAULT_OFFSET = 33

const Parallax = ({
  offset, styleOuter, styleInner, children, ...props
}) => {
  let parallaxController = null

  if (typeof window !== 'undefined') {
    parallaxController = useController().parallaxController
  }

  const windowSize = useWindowSize()
  // @TODO: Get windowSize from global context provider
  const verticalOffset = offset || DEFAULT_OFFSET

  useEffect(() => {
    if (typeof window !== 'undefined') {
      parallaxController.update()
    }
  }, [windowSize])

  return (
    <ParallaxElem
      tagOuter="div"
      y={[-verticalOffset, verticalOffset]}
      styleOuter={{
        overflow: 'hidden',
        ...styleOuter
      }}
      styleInner={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...styleInner
      }}
      {...props}
    >
      {children}
    </ParallaxElem>
  )
}

Parallax.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  styleOuter: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  styleInner: PropTypes.object,
  offset: PropTypes.number,
  children: PropTypes.node
}

export default Parallax
